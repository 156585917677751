import { inject } from '@angular/core';
import { CanActivateFn, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { RoutingService } from '@routing/services/routing.service';
import { Observable } from 'rxjs';

export const BAGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> => {
  const router = inject(Router);
  const routingService = inject(RoutingService); // inject RoutingService
  const locale = routingService.getLocale();
  const allowedNames: string[] = ['a-newport', 'j-buntin', 'r-lucas','e-jones','k-griffin','k-dann','m-gurrin'];
  const baName: string = route.params['name'];

  if (allowedNames.includes(baName)) {
    return true;
  }
  if (route.params['name'] == 'events'){
    const language = locale.language;
    const market = locale.market;
    return router.parseUrl(`${language}-${market}/whats-on`);
  } else {
    return router.parseUrl('/');
  }
};