import {
  Component,
  ViewEncapsulation,
  Inject,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { AgeGateSubmissionService } from 'bsi-toolkit-agegate';
import { DOCUMENT, Location } from '@angular/common';
import { AuthService } from '@core/auth/auth.service';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { RoutingService } from '@routing/services/routing.service';
import {
  UtilServiceProxy,
  TaxMarket,
  TaxLanguage,
  Config,
} from '@proxy/service-proxies';
import { AgeGateService } from './age-gate.service';
import { TranslationExtService } from '@shared/services/translation-ext.service';
import { Router, ActivatedRoute, RoutesRecognized } from '@angular/router';
import { MarketService } from '@shared/services/market.service';
import { PageBase, PageStatus, PageLoadType } from '@core/interfaces/page-base';
import { PageComponentBase } from '@core/classes/page-component-base';
import { RoutingLocale } from '@routing/interfaces/routing';
import { PageService } from '@shared/services/page.service';
import { get } from 'lodash';
import { ConfigService, ConfigKeys } from '@shared/services/config.service';
import { first } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ClubSuntoryService } from '@shared/services/club-suntory.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-age-gate',
  templateUrl: './age-gate.component.html',
  styleUrls: ['./age-gate.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AgeGateComponent extends PageComponentBase implements PageBase {
  @ViewChild('ageGateTab') ageGateTab: ElementRef;
  @ViewChild('logInTab') logInTab: ElementRef;
  @ViewChild('ageGateContent') ageGateContent: ElementRef;
  @ViewChild('logInContent') logInContent: ElementRef;
  marketRecievedInternal = false;

  public show = false;
  public activeMarkets: TaxMarket[];
  private allMarkets: TaxMarket[];
  public international = false;
  private currentCountry = '';
  public languages;
  public selectedLangauge;
  private ageGatePassed: boolean = false;
  private agInitialized: boolean = false;
  public routeDisabled: boolean = false;

  public termLink: string;
  public privacyLink: string;
  public cookieLink: string;

  public logInTabTitle: string;
  public verifyAgeTabTitle: string;

  // testing
  private selectedCountry;


  private isAUSubscription: Subscription | undefined;
  isClubSuntory: boolean = false;

  constructor(
    private agegate: AgeGateSubmissionService,
    @Inject(DOCUMENT) private _document,
    protected auth: AuthService,
    protected routingService: RoutingService,
    private utilApi: UtilServiceProxy,
    private agSvc: AgeGateService,
    private translateSvc: TranslationExtService,
    private route: Router,
    protected router: ActivatedRoute,
    protected marketSvc: MarketService,
    protected localizeSvc: LocalizeRouterService,
    protected pageSvc: PageService,
    private configSvc: ConfigService,
    private location: Location,
    private translate: TranslateService,
    private clubSuntorySvc: ClubSuntoryService
  ) {
    // super(router, auth, routingService, localizeSvc, marketSvc);
    super(pageSvc);

    // Check if age gate cookie already set. If the age gate should not be enabled then age gate is by definition passed
    this.ageGatePassed = !this.agegate.enabled();
  }

  onPageLoad(event: PageStatus) {

    this.isAUSubscription = this.clubSuntorySvc.isAU$.subscribe(result => {
      this.isClubSuntory = result;
    });
    this.verifyAgeTabTitle = this.translate.instant('AgeGate.Verify');
    this.logInTabTitle = this.translate.instant('AgeGate.LogIn');

    if (!this.ageGatePassed) {
      this.allMarkets = event.markets;
      this.routeDisabled =
        get(event, `routeData['AgeGateDisabled']`) === true ? true : false;
      if (this.routeDisabled) {
        this._document.body.classList.add('scrollImportant');
      } else {
        this._document.body.classList.remove('scrollImportant');
      }

      if (event.type === PageLoadType.Initial) {
        if (event.authenticated) {
          // bypass age gate
          this.agegate.setVerified(true);
          this.ageGatePassed = true;
        } else if (this.agegate.enabled()) {
          // Only US should have correct date format
          this.international = this.routingService.getLocale().market !== 'us';

          // If age gate NOT disabled then show
          if (this.routeDisabled !== true) {
            this.agInitialized = true;
            this.initAgeGate();
          }
        } else {
          this.ageGatePassed = true;
        }
      }

      // If routing took place and age gate hasn't been initialized then check if we should enabled
      if (event.type === PageLoadType.ParamChange) {
        // If age gate NOT disabled then show
        if (!this.agInitialized) {
          if (this.routeDisabled !== true) {
            this.agInitialized = true;
            this.initAgeGate();
          }
        } else {
          this.handleLanguageEvent(event);
        }
      }

      if (event.type === PageLoadType.Language && this.agegate.enabled()) {
        // Handle language change only if route doesn't have age gate disabled
        if (!this.routeDisabled) {
          this.handleLanguageEvent(event);
        }
      }
    }
  }

  private handleLanguageEvent(event: PageStatus) {
    if (event.type === PageLoadType.ParamChange) {
      this.currentCountry = event.locale.market;
    }

    const countries = this.translateSvc.getCountriesAgeGate();
    this.agegate.setCountryNamesTranslation(countries);
    this.agegate.setCountryByCode(this.currentCountry);
    // We need to manually handle change if only language changed
    this.handleCountry(this.currentCountry);
  }

  private initAgeGate() {
    const locale = this.routingService.getLocale();
    this.currentCountry = locale.market;

    // Handles display of age gate component
    this.agSvc.AgeGateShow.subscribe((show) => {
      this.show = show;

      if (show) {
        this._document.body.classList.add('noScroll');
      } else {
        this._document.body.classList.remove('noScroll');
      }
    });

    // Init country change listener
    this.initCountryChange();

    // Get markets
    this.activeMarkets = this.marketSvc.parseActiveMarkets(this.allMarkets);
    // Handle events with age gate
    this.agegate.show.subscribe((res) => {
      this.show = res.show;

      // Translate countries if need be
      if (locale.language !== 'en') {
        this.agegate.setCountryNamesTranslation(
          this.translateSvc.getCountriesAgeGate()
        );
      }
      // Set the country code
      this.agegate.setCountryByCode(this.currentCountry);

      // Handle actions by age gate
      if (res.submitUnderage) {
        // Go to drink smart youngin
        this._document.location.href = 'https://www.drinksmart.com';
      } else if (res.show) {
        this._document.body.classList.add('noScroll');
      } else {
        this._document.body.classList.remove('noScroll');
        this._document.body.classList.add('scrollImportant');
        this.ageGatePassed = true;
      }
    });

    // Get user's country from API call
    this.utilApi.countryCode().subscribe((code) => {
      // We only set the market based on IP if the user is on the global site and at the root
      const path = this.location.path() === '' ? '/' : this.location.path();
      if (this.routingService.isGlobal() && path === '/') {
        // If global use IP based
        this.currentCountry = code;
      } else {
        // Else use the market from url
        this.currentCountry = this.routingService.getLocale().market;
      }
      this.agegate.setCountryByCode(this.currentCountry);
      this.handleCountry(this.currentCountry);
    });
  }

  private isInternational(country: string) {
    return country.toLowerCase() !== 'us';
  }

  private initCountryChange() {
    // handle country switching and dates
    this.agegate.countryChanged.subscribe((country) => {
      // If country changed (form event) AND we are on the root site of the market ie /en-au
      const path = this.location.path() === '' ? '/' : this.location.path();
      if (
        country.formEvent &&
        this.currentCountry.toLowerCase() !==
        country.country.code.toLowerCase() &&
        path.replace(this.localizeSvc.parser.currentLang, '') === '/'
      ) {
        this.international = country.country.code.toLowerCase() !== 'us';
        this.currentCountry = country.country.code;

        // If country is changed to an active market route
        this.handleCountry(country.country.code);
      }

      // const path = this.location.path() === '' ? '/' : this.location.path();
      // if (this.currentCountry.toLowerCase() !== country.code.toLowerCase() && path.replace(this.localizeSvc.parser.currentLang,'') === '/') {
      //   this.international = country.code.toLowerCase() !== 'us';
      //   this.currentCountry = country.code;

      //   // If country is changed to an active market route
      //   this.handleCountry(country.code);
      // }
      // Stores country code for usage in user registration
      localStorage.setItem("blendCountry", country.country.code);
    });
  }

  private handleCountry(code: string) {
    // this.marketSvc.getMarketByCode(code).subscribe(market => {
    const market = this.marketSvc.parseMarketByCode(code, this.allMarkets);

    if (market && market.active) {
      const locale = this.routingService.getLocale();

      // Set languages for market
      this.languages = market.languages;
      // If locale country and code are the same we don't need to change market
      if (code.toLowerCase() === locale.market.toLowerCase()) {
        const lang = market.languages.find(
          (lang: TaxLanguage) =>
            lang.languageCode.toLowerCase() === locale.language.toLowerCase()
        );
        this.setLanguage(
          lang ? lang['languageCode'] : market.defaultLanguage['languageCode']
        );
      } else {
        // If different market we have to navigate
        this.route.navigate([
          '/',
          `${market.defaultLanguage['languageCode']
          }-${market.country.toLowerCase()}`,
        ]);
        this.setLanguage(market.defaultLanguage['languageCode']);
      }
      // } else if (code === 'MX') {
      //   this.route.navigate(['/', 'es-us']);
      //   this.setLanguage('es');
    } else {
      this.route.navigate(['/']);
      this.languages = [];
      this.selectedLangauge = undefined;
    }

    // Check for international
    this.international = this.isInternational(code);
    this.handleComplianceLinks();
    // });
  }

  private handleComplianceLinks() {
    this.configSvc
      .getConfigurations([
        ConfigKeys.TermsLink,
        ConfigKeys.PrivacyLink,
        ConfigKeys.CookieLink,
      ])
      .subscribe((links) => {
        this.termLink = links[ConfigKeys.TermsLink];
        this.privacyLink = links[ConfigKeys.PrivacyLink];
        this.cookieLink = links[ConfigKeys.CookieLink];
      });
  }

  private setLanguage(language: string) {
    const lang = this.languages.find(
      (lang: TaxLanguage) =>
        lang.languageCode.toLowerCase() === language.toLowerCase()
    );
    this.selectedLangauge = lang ? lang.languageCode : 'en';
  }

  showAgeGateForm() {
    this.ageGateTab.nativeElement.classList.add('active');
    this.ageGateContent.nativeElement.classList.add('active');
    this.logInTab.nativeElement.classList.remove('active');
    this.logInContent.nativeElement.classList.remove('active');
  }

  showLoginForm() {
    this.logInTab.nativeElement.classList.add('active');
    this.logInContent.nativeElement.classList.add('active');
    this.ageGateTab.nativeElement.classList.remove('active');
    this.ageGateContent.nativeElement.classList.remove('active');
  }

  changeLanguage(language) {
    const langTo = language.target.value;
    this.selectedLangauge = this.languages.find(
      (lang: TaxLanguage) =>
        lang.languageCode.toLowerCase() === language.target.value.toLowerCase()
    ).languageCode;
    this.routingService.changeLanguage(langTo);
    // this.handleCountry(this.currentCountry);
  }
}
