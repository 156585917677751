import {
  Component,
  Output,
  EventEmitter,
  ViewChild,
  AfterViewInit,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import { AuthService } from '@core/auth/auth.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { MatDialog } from '@angular/material/dialog';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { DialogComponent } from '@core/components/dialog/dialog.component';
import { GtmService } from 'bsi-toolkit';
import {
  MenuServiceProxy,
  BaseInput,
  TaxMarket,
  TaxLanguage,
  Base,
  TaxMenu,
} from '@proxy/service-proxies';
import { RoutingService } from '@routing/services/routing.service';
import { RoutingLocale } from '@routing/interfaces/routing';

import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { PageBase, PageStatus, PageLoadType } from '@core/interfaces/page-base';
import { PageComponentBase } from '@core/classes/page-component-base';
import { MarketService } from '@shared/services/market.service';
import { PageService } from '@shared/services/page.service';
import { MatSnackBar } from '@angular/material/snack-bar';

import { ImgSafePathPipe } from '@core/pipes/img.pipe';
import { RegistrationDialogComponent } from '@shared/registration-dialog/registration-dialog.component';
import { JoinService } from '@shared/user-registration/services/join.service';
import { ClubSuntoryService } from '@shared/services/club-suntory.service';
import { Subscription } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent
  extends PageComponentBase
  implements PageBase, AfterViewInit {
  loginForm: FormGroup;
  public isCollapsed = true;
  authenticated = false;
  msgError = false;

  currentMarket: TaxMarket;
  activeMarkets: TaxMarket[];
  allMarkets: TaxMarket[]; // ListResultDtoOfTaxMarket;
  languages: TaxLanguage[] | Base[];
  defaultLanguage: TaxLanguage | Base;
  activeLanguage: TaxLanguage | Base;

  navItems = [];

  isMobile: boolean;
  isMultipleLanguages: boolean;
  public showLanguageSwitcher: boolean = false;

  initialLoad = true;

  isAuthGuard: boolean;

  locale: RoutingLocale;
  // boolean that controls the hamburger icon
  isChecked: boolean = false;

  private isAUSubscription: Subscription | undefined;
  isClubSuntory: boolean = false;

  @Output() public sidenavToggle = new EventEmitter();
  @ViewChild('nav') nav;
  @ViewChild('header') header;
  @ViewChild('newsParent') newsParent;
  @ViewChild('subNewsList') subNewsList;
  @ViewChild('languageOverlay') languageOverlay;
  @ViewChild('hamburgerBars') hamburgerBars;
  @ViewChild('marketSelector') marketSelector;

  constructor(
    protected auth: AuthService,
    private formBuilder: FormBuilder,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private menuService: MenuServiceProxy,
    @Inject(DOCUMENT) private _document,
    @Inject(PLATFORM_ID) private platform: Object,
    private gtm: GtmService,
    protected routingService: RoutingService,
    protected route: Router,
    private translate: TranslateService,
    private breakPointObserver: BreakpointObserver,
    protected localize: LocalizeRouterService,
    protected router: ActivatedRoute,
    protected marketSvc: MarketService,
    protected pageSvc: PageService,
    public imgSafePipe: ImgSafePathPipe,
    private joinSvc: JoinService,
    private clubSuntorySvc: ClubSuntoryService
  ) {
    // super(router, auth, routingService, localize, marketSvc);
    super(pageSvc);
  }

  onPageLoad(event: PageStatus) {
    this.isAUSubscription = this.clubSuntorySvc.isAU$.subscribe(result => {
      console.log(result);
      this.isClubSuntory = result;
    });
    this.isAuthGuard = event.routeData.AuthGuard;

    if (event.type === PageLoadType.Initial) {
      this.checkBrowserWidth();
    }

    if (event.type === PageLoadType.Authentication) {
      this.authenticated = event.authenticated;
    } else if (
      event.type === PageLoadType.Initial ||
      event.type === PageLoadType.Language
    ) {
      this.authenticated = event.authenticated;
      const locale = event.locale;
      this.locale = locale;
      /**
       * Get Menu Items
       */
      if (isPlatformBrowser(this.platform)) {
        this.menuService
          .main(
            new BaseInput({
              languageCode: locale.language,
              market: locale.market,
              publishedStatus: '1',
            })
          )
          .subscribe((data) => {
            // ES6 code which mean const headerData = data.items
            this.navItems = [];
            const { items: headerData } = data;

            headerData.forEach((item) => {
              if (item.parent === undefined) {
                this.navItems.push({
                  parent: item,
                  children: [],
                  allPrivateLinks: false,
                });
              }
            });
            headerData.forEach((item) => {
              if (item.parent !== undefined) {
                const parent = this.navItems.find((x) => {
                  return x.parent.id === item.parent.id;
                });
                parent.children.push(item);
              }
            });
            this.checkPrivateLinks(this.authenticated);
          });

        this.allMarkets = event.markets;
        this.updateMarketInfo(this.routingService.getLocale());
      }

      /**
       * Handle markets and languages
       */
      // this.allMarkets = event.markets;
      // this.updateMarketInfo(this.routingService.getLocale());
    }

    // Get Active Markets
    this.activeMarkets = this.marketSvc.parseActiveMarkets(event.markets);

    // Filter markets based on Club Suntory variable
    const filterCountries = ["AU", "NZ"];
    this.activeMarkets = this.activeMarkets.filter((market) =>
      this.isClubSuntory ? filterCountries.includes(market.country) : !filterCountries.includes(market.country)
    );

    // Sort markets by name
    this.activeMarkets.sort((a, b) => a.name.localeCompare(b.name));
  }

  updateMarketInfo(locale: RoutingLocale) {
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
      usercode: ['', Validators.nullValidator],
    });

    this.currentMarket = this.allMarkets.find((market) => {
      return market.country.toLowerCase() === locale.market;
    });

    this.currentMarket = this.currentMarket
      ? this.currentMarket
      : this.marketSvc.getGlobalMarket(this.allMarkets);

    this.defaultLanguage = this.currentMarket.defaultLanguage;
    this.isMultipleLanguages = this.currentMarket.languages.length > 1;

    if (this.isMultipleLanguages) {
      this.activeLanguage = this.currentMarket.languages.find(
        (language: TaxLanguage) =>
          language.languageCode.toLowerCase() === locale.language.toLowerCase()
      );

      this.languages = this.currentMarket.languages.sort((a, b) => {
        const textA = a['name'].toUpperCase();
        const textB = b['name'].toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    }
  }

  /**
   * Returns true if there is one nav item visible for non auth person
   * @param items
   */
  public visibleChildren(items: TaxMenu[]): boolean {
    return items.some((item) => !item.private);
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platform)) {
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: 'auto',
      maxWidth: '90vw',
      data: { authenticated: this.authenticated },
    });

    this.route.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        dialogRef.close();
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.authenticated = true;
        this.checkPrivateLinks(true);
      } else if (result === false) {
        this.authenticated = false;
      }
    });
  }

  openRegistration(): void {
    this.joinSvc.openRegistration();
    // const dialogRef = this.dialog.open(RegistrationDialogComponent, {
    //   width: '400px',
    //   maxWidth: '90vw',
    //   data: { locale: this.locale },
    //   panelClass: 'registration-dialog',
    // });
  }

  /**
   *
   * @param show - Options. If true - menu will show, if false - menu will close
   */
  toggleMenu(show?: boolean) {
    this.isChecked = !this.isChecked;
    this.header.nativeElement.classList.toggle('show');
    this._document.body.classList.toggle('noScroll');
    // check if market/language menu is open. If so close it
    if (
      !this.header.nativeElement.classList.contains('show') &&
      this.marketSelector.nativeElement.classList.contains('showOptions')
    ) {
      this.marketSelector.nativeElement.classList.remove('showOptions');
    }

    if (this.header.nativeElement.classList.contains('search')) {
      this.header.nativeElement.classList.remove('search');
    }
  }

  public closeMenu() {
    if (this.isChecked) {
      this.isChecked = false;
      this.header.nativeElement.classList.remove('show');
      this._document.body.classList.remove('noScroll');
    }
  }

  private hideNavMenu() {
    this.nav.nativeElement.classList.remove('show');
    this.header.nativeElement.classList.remove('menu-show');
    // this.isChecked = false;
    // this.hamburgerBars.nativeElement.classList.remove('crossed');
    this.hideLangMenu();
  }

  private hideLangMenu() {
    if (this.languageOverlay) {
      this.languageOverlay.nativeElement.classList.remove('expose-lmenu');
      this.showLanguageSwitcher = false;
    }
  }

  private toggleNavMenu() {
    this.nav.nativeElement.classList.toggle('show');
    this.header.nativeElement.classList.toggle('menu-show');
    // this.hamburgerBars.nativeElement.classList.toggle('crossed');

    if (!this.nav.nativeElement.classList.contains('expose-menu')) {
      // Menu closed
      this.hideLangMenu();
    }
  }

  public onToggleSidenav = () => {
    this.sidenavToggle.emit();
  };

  logOut(event) {
    if (isPlatformBrowser(this.platform)) {
      this.auth.signOut();
      this.loginForm.value.email = '';
      this.loginForm.value.password = '';
      this._snackBar.open(this.translate.instant('Header.LogOutSuccess'), '', {
        duration: 5000,
        panelClass: 'tb-snackbar-success',
      });

      if (this.isMobile) {
      }
      // this.toggleMenu(false);
      this.checkPrivateLinks(false);
      // dataLayer Push
      this.gtm.pushEvent({ event: 'e_accountLogout' });
    }
    if (this.isAuthGuard) {
      this.route.navigate(['/']);
    }
    // location.reload();
  }

  routeAccountSettings() {
    // this.toggleMenu(false);
    this.route.navigate([this.localize.translateRoute('/account-edit')]);
  }

  routeJoin() {
    this.toggleMenu(false);
    this.route.navigate([this.localize.translateRoute('/join')]);
  }

  routePath(path: string) {
    // this.toggleMenu(false);
    this.closeMenu();
    this.route.navigate([this.localize.translateRoute(path)]);
  }

  toggleLanguageOverlay() {
    if (isPlatformBrowser(this.platform)) {
      if (this.header.nativeElement.classList.contains('menu-show')) {
        // this.toggleMenu(false);
        this.header.nativeElement.classList.remove('menu-show');
      }
      this.showLanguageSwitcher = !this.showLanguageSwitcher;
      this.languageOverlay.nativeElement.classList.toggle('expose-lmenu');
    }
  }

  changeActiveLanguage(event) {
    if (isPlatformBrowser(this.platform)) {
      this.routingService.changeLanguage(event.languageCode);
      // this.parser.translateRoutes(`${event.languageCode}-${locale.market}`);
      //   this.toggleMenu(false);
      // reloads the page
      // had to set timeout to give the url time to change over to new language before reloading page
      setTimeout(() => {
        window.location.reload();
      }, 500);
      this.marketSelector.nativeElement.classList.remove('showOptions');
      // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    }
  }

  changeActiveMarket(event) {
    // event.preventDefault();
    this.routingService.changeMarket(event);
    this.currentMarket = this.activeMarkets.find((market) => {
      return market.country === event.country;
    });
    this.marketSelector.nativeElement.classList.remove('showOptions');
  }
  // changeActiveMarket(selectedMarket, event) {
  // 	event.preventDefault();
  // 	this.routingService.changeMarket(selectedMarket);
  // 	this.currentMarket = this.activeMarkets.find((market) => {
  // 		return market.country === event.currentTarget.dataset.market;
  // 	});
  // }

  toggleNavActive(listItem) {
    if (isPlatformBrowser(this.platform)) {
      listItem.classList.toggle('active');
      listItem.children[1].classList.toggle('active');
    }
  }

  checkBrowserWidth() {
    this.breakPointObserver
      .observe([`(max-width: 911px)`])
      .subscribe((result) => {
        if (result.matches) {
          this.isMobile = true;
        } else {
          this.isMobile = false;
        }
      });
  }

  public toggleModal(event): void {
    event.currentTarget.children[0].classList.toggle('showOptions');
  }
  // prevent showList from triggering on child click
  public stopPropagation(event: MouseEvent): void {
    event.stopPropagation();
  }

  toggleSearch() {
    this.header.nativeElement.classList.toggle('search');
  }

  checkPrivateLinks(authenticated: boolean) {
    this.navItems.map((parent) => {
      let totalLinks = 0;
      let privateLinks = 0;
      parent.children.forEach((child) => {
        totalLinks++;
        if (child.private) {
          privateLinks++;
        }
      });
      if (totalLinks !== 0 && totalLinks === privateLinks && !authenticated) {
        parent.allPrivateLinks = true;
      } else {
        parent.allPrivateLinks = false;
      }
    });
  }
  trackMarkets(index, item) {
    // console.log(index, item);
    return item.country;
  }
}
