import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ClubSuntoryService {
  private currentLocaleSubject = new BehaviorSubject<string>('en-US');
  currentLocale$: Observable<string> = this.currentLocaleSubject.asObservable();

  private isAUSubject = new BehaviorSubject<boolean>(false);
  isAU$: Observable<boolean> = this.isAUSubject.asObservable();

  constructor(private router: Router, @Inject(PLATFORM_ID) private platform: Object) {
    this.currentLocale$.subscribe(locale => {
      this.updateIsAU(locale);
    });

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.updateFullUrlContainsClubSuntory();
    });
  }

  private updateIsAU(locale: string): void {
    this.isAUSubject.next(this.isAUFromLocale(locale) || this.fullUrlContainsClubSuntory());
  }

  private isAUFromLocale(locale: string): boolean {
    return locale.toLowerCase() === 'au' || locale.toLowerCase() === 'nz';
  }

  private fullUrlContainsClubSuntory(): boolean {
    if (isPlatformBrowser(this.platform)) {
      const fullUrl = window.location.href.toLowerCase();
      console.log('Full URL:', fullUrl);
      return fullUrl.includes('clubsuntory');
    }
    return false;
  }

  private updateFullUrlContainsClubSuntory(): void {
    this.isAUSubject.next(this.isAUFromLocale(this.currentLocaleSubject.value) || this.fullUrlContainsClubSuntory());
  }

  setLocale(locale: string): void {
    this.currentLocaleSubject.next(locale);
  }

  getLocale(): string {
    return this.currentLocaleSubject.value;
  }

  getCurrentFullUrl() {
    if (isPlatformBrowser(this.platform)) {
      return window.location.href;
    }
    return ''; // Or any default value if not on browser platform
  }
}